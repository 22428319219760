import React, { useRef } from 'react';
import { StaticImage } from "gatsby-plugin-image";
import FeaturedPost from "../components/FeaturedPost";
import useElementOnScreen from '../../utilities/useElementObserver';


const About = () => {
    
    const targetRef = useRef(null);
    const isVisible = useElementOnScreen({
        root: null,
        rootMargin: "50px",
        threshold: .4
    }, targetRef);

    const aboutText = (
        <div className="p-8 h-full">
            <p className="text-white relative font-semibold text-3xl z-50"> Your Go-to Roofing Specialists</p>
            <br />
            <p className="text-white relative font-semibold text-3xl z-50"> Accredited Certainteed Certified Installers </p>
            <br />
            <p className="text-white relative font-semibold text-3xl z-50"> Manufacturer's Warranty </p>

            <StaticImage 
                src="../images/veteranlogo.png"
                formats={["auto", "webp", "avif"]} 
                alt="veteran owned logo"
                layout="constrained"
                height={400}
                width={350}
                className=""
            />
        </div>
    )

    return (
        <section className="from-black to-gray-900  bg-gradient-to-b m-auto w-full p-8" id="about" >
            <div ref={targetRef} className=" w-full h-full flex flex-col m-auto lg:px-8">
                <div className="w-full my-3">
                    <StaticImage 
                        src="../images/rooferIcon.png"
                        alt=""
                        width={75}
                        height={75}
                        formats={["auto", "webp", "avif"]} 
                        className=""
                    />
                    <h1 className="inline-flex sm:text-6xl align-middle pt-4 text-4xl text-gray-500">About us</h1>
                </div>
                <div className=" about-bg mx-auto w-full" >
                        
                    <div className="flex flex-col lg:flex-row text-wrap w-full justify-center text-center">
                        <div className={`${isVisible? "slide-lft show" : "slide-lft"} col-start-1 col-span-1 w-full`}>
                            {aboutText}
                        </div>

                        <div className="col-start-2 col-span-1 w-full m-auto pl-4">
                            <div className='lg:max-w-xl max-w-lg m-auto'>
                                <FeaturedPost /> 
                            </div>
                            
                            <p className="text-white text-lg">Check us out on Social Media!</p>
                            
                            <a href="https://www.facebook.com/summitextnj" className="inline-flex hover:scale-110 transition ease-out">
                                <StaticImage 
                                    src="../images/facebookTransparent.png"
                                    alt="link to facebook"
                                    width={75}
                                    height={75}
                                    formats={["auto", "webp", "avif"]} 
                                />
                            </a>
                        </div>       
                    </div>
                </div>
            </div>
        </section>
    )
};

export default About;
