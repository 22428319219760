import React from 'react';
import { graphql, useStaticQuery, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardQuestion } from "@fortawesome/free-solid-svg-icons";


const FeaturedPost = () => {

  const data = useStaticQuery(graphql`
    query featuredPost {
      mdx(frontmatter: {featured: {eq: true}}) {
        id
        frontmatter {
          description
          slug
          title
          type
          thumb {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  
  // console.log(data);
  const { 
    mdx: {
      id,
      frontmatter: {
          description, 
          slug, 
          title, 
          thumb
          
      }}
    } = data;
  
  
  const thumbImage = getImage(thumb);

  return (
    
    <div className="flex relative bg-gray-300 rounded-md flex-col m-auto p-4 z-30 " >
      <Link to={"/" + slug} id={id}> 
        <div className="w-full h-full" >
            <h3 className="text-2xl text-blue-900 text-center font-semibold p-4">
              {title}
            </h3>
            <GatsbyImage 
              image={thumbImage} 
              alt="featured article image" 
              formats={["auto", "webp", "avif"]} 
              layout="fullWidth"
              className="w-full h-auto rounded-t-md"
            />
            <div className="flex flex-col">
                <p className="text-blue-900 bg-white m-0 p-4 rounded-b-md text-2xl sm:text-2xl py-8" style={{fontFamily: "rockwell-nova"}}>
                  {description} {" "}
                  <FontAwesomeIcon className="text-blue-900 text-2xl" icon={faClipboardQuestion} />
                </p>
                
            </div>
                
        </div>
      </Link>
    </div>
    
  )
}

export default FeaturedPost;