import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import useElementOnScreen from '../../utilities/useElementObserver';
import Button from './Button'
import { Link } from "gatsby";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";

const Financing = () => {
    
    const targetRef = useRef(null);
    const isVisible = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0
    }, targetRef);
    

  return (
      <>
        <section ref={targetRef} className=" w-full h-auto py-8 bg-gradient-to-b from-gray-300 via-gray-500 to-gray-700" id="financing">
            <div className={isVisible? "slide-rt show w-full " : "slide-rt w-full"}>
                <h2 className="md:text-6xl text-4xl text-blue-900 py-8"> 
                    <FontAwesomeIcon icon={faCreditCard} className="px-4 text-4xl" />
                    Need financing?
                </h2>
                <div className="sm:flex sm:flex-col w-full h-full md:space-y-8">
                    
                    <div className="md:grid md:grid-cols-2 h-full w-full ">  
                        
                        <div className="col-start-1 col-span-1 w-full h-full">
                            <StaticImage 
                                src="../images/financing-family.jpeg"
                                layout="fullWidth"
                                className=""
                                  alt="couple reading financing options "
                                  formats={["auto", "webp", "avif"]} 
                            /> 
                        </div>
                        
                        <div className="col-start-2 col-span-1">
                            <div className="flex flex-col place-content-center w-full h-full">
                                <StaticImage 
                                    src="../images/enhancify logo.png"
                                      alt="enhancify logo"
                                    formats={["auto", "webp", "avif"]} 
                                    layout="fullWidth"
                                    className="m-4"
                                /> 
                                <p className="container text-white lg:text-2xl sm:text-lg p-4 w-full font-serif">
                                    We partnered up with a company called Enhancify, who offers 
                                    reliable and affordable financing options to people nationwide 
                                    through their online platform.
                                </p>
                                <div className="m-auto bg-transparent hover:bg-white hover:border-blue-900 hover:scale-105 transition-all rounded-lg">
                                    <Link to="/enhancify" className="h-full text-white hover:text-blue-900 hover:border-blue-900 transition-all">
                                        <Button 
                                            value={"Read More"}
                                            className="w-3/6 m-auto "
                                        />
                                    </Link>
                                </div>
                                
                            </div>
                            
                        </div> 
                    </div>
                </div>                 
            </div>  
        </section>
        
    </>
  )
};

export default Financing;