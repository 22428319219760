import React, { useRef } from 'react';

const Contact = () => {

        // set reference to null and update with handleSubmit
        const nameInput = useRef(null);
        const emailInput = useRef(null);
        const addressInput = useRef(null);
        const cityInput = useRef(null);
        const stateInput = useRef(null);
        const zipInput = useRef(null);
        const messageInput = useRef(null);
    
        // When the form is submitted, send the form values
        // to gatbsy function for processing.
        const handleSubmit = async (e) => {
            e.preventDefault();
    
            const formInput = [
                ["name", nameInput.current.value],
                ["email", emailInput.current.value],
                ["address", addressInput.current.value],
                ["city", cityInput.current.value],
                ["state", stateInput.current.value], 
                ["zip", zipInput.current.value], 
                ["message", messageInput.current.value]
            ];
            const formData = new FormData();
    
            for (var input of formInput) {
                formData.append(input[0], input[1]);
            };
            
            const jsonObject = Object.fromEntries(formData);

            const response = await window
                .fetch(`/api/form`, {
                    method: `POST`,
                    headers: {
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(jsonObject),
                })
                .then(res => res.json())
                .then(document.getElementById("myForm").reset())
                .then(document.getElementById("submitMessage").classList.remove("hidden"));
        }
        
    return (
        
        <section className=" p-4 h-[75vh] bg-gradient-to-b from-gray-700 to-gray-900" id="contact">
            <div className="container p-4 text-white flex flex-col h-full w-full m-auto place-content-center">
                <p className="text-white text-3xl">
                    Have a question? Ask here!
                </p>
                <div className="flex flex-col place-content-center text-2xl">
                    <form 
                        onSubmit={handleSubmit}
                        id="myForm"
                        >
                        <div className="w-full">
                            <label htmlFor="formGridName" >Name</label>
                            <input ref={nameInput} id="formGridName" className="w-full bg-gray-300 rounded-lg text-blue-900 px-4" type="name" name="name" placeholder="" required />
                        </div>
                        <div >
                            <label htmlFor="formGridEmail">Email</label>
                            <input ref={emailInput} id="formGridEmail" className="w-full bg-gray-300 rounded-lg text-blue-900 px-4" type="email" name="email" placeholder="" required />
                        </div>

                        <div className="mb-3"  >
                            <label htmlFor="formGridAddress" >Address</label>
                            <input ref={addressInput} id="formGridAddress" className="w-full bg-gray-300 rounded-lg text-blue-900 px-4" name="address" placeholder="" required />
                        </div>

                        <div className="flex flex-row mb-3 w-full justify-between ">
                            <div >
                                <label htmlFor="formGridCity">City</label>
                                <input ref={cityInput} id="formGridCity" className="w-full bg-gray-300 rounded-lg text-blue-900 pl-4" name="city" placeholder="" required />
                            </div>

                            <div className="flex-col">
                                <label htmlFor="formGridState">State</label>
                                <input ref={stateInput} id="formGridState" className="w-full bg-gray-300 rounded-lg text-blue-900 pl-4" name="state" placeholder="" required />
                            </div>

                            <div className="flex-col">
                                <label htmlFor="formGridZip" >Zip</label>
                                <input ref={zipInput} id="formGridZip" className="w-full bg-gray-300 rounded-lg text-blue-900 pl-4" name="zip" placeholder="" required />
                            </div>
                        </div>

                        <div className="flex-col mb-3 h-2/5 pb-8">
                            <label htmlFor="formGridMessage">Your Message</label>
                            <input id="formGridMessage" className="w-full h-full px-4 bg-gray-300 rounded-lg text-blue-900" ref={messageInput} as="textarea" rows={8} name="message" placeholder="enter your message here" required />
                        </div>

                        <div className="flex flex-col py-4">
                            <button type="submit" className="px-8 py-3 font-semibold rounded hover:bg-white hover:text-slate-800 hover:scale-105 duration-300 transition w-fit m-auto " id="submitButton">
                                Submit
                            </button>
                        </div>
                        
                        
                    </form>
                    <div className="hidden text-white text-center text-xl" id="submitMessage">
                        Thanks for reaching out! We'll get back to you shortly
                    </div>
                </div>
            </div>
           
        </section>
    )
}
export default Contact;