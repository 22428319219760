import React, { useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import useElementOnScreen from '../../utilities/useElementObserver';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faToolbox } from "@fortawesome/free-solid-svg-icons";


const Services = () => {

        
    const targetRef = useRef(null);
    const isVisible = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0
    }, targetRef);

    const services = (<div className=" space-y-4 h-auto text-center ">   
        <p className="text-2xl text-white" >Residential & Commercial Roofing</p>
        <p className="text-2xl text-white" >Roofing Maintenance & Repair</p>
        <p className="text-2xl text-white" >Gutters</p>
        <p className="text-2xl text-white" >Fascia Metal Capping</p>
        <p className="text-2xl text-white" >
            Siding 
            <span className="text-xl text-blue-900 bg-gray-300 rounded-lg ml-8 p-2 transition hover:animate-bounce duration-75 "> 
                NEW! 
            </span>
        </p>
    </div>)

    return (
        <section className="bg-gradient-to-b from-gray-900 to-gray-700 pb-8" id="services">
            <div ref={targetRef} className={isVisible? "slide-up show" : "slide-up"}>
               
                <div className="md:grid md:grid-cols-2 w-full h-full max-w-screen py-4  align-middle  space-y-8">
                    <div className="col-start-1 col-span-1 space-y-8 h-full ">
                        <h2 className="md:text-6xl text-4xl text-center content-center py-4">
                            Services  
                            <FontAwesomeIcon icon={faToolbox} className="text-blue-900 text-5xl px-4" />
                        
                        </h2>
                        {services}
                    </div>
                    <div className="col-start-2 col-span-1 h-full">
                         <StaticImage 
                            src="../images/certainteed_pic.jpeg"
                            formats={["auto", "webp", "avif"]} 
                            alt="picture of roof with Certainteed Brand"
                            layout="fullWidth"
                            className="" 
                        />
                    </div>
                </div>
            </div>
          


        </section>
    )

}

export default Services;