import React, {useEffect} from "react";
import { useStaticQuery, graphql } from "gatsby";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight, faStar } from "@fortawesome/free-solid-svg-icons";

const ReviewItem = () => {

    const data = useStaticQuery(graphql`
        query Reviews {
            allGooglePlacesReview(sort: {fields: time, order: DESC}) {
                edges {
                    node {
                        id
                        text
                        author_name
                        rating
                        relative_time_description
                    }
                }
            }
        }
    `);

    const edges = data.allGooglePlacesReview.edges;

    const starRating = (rating) => {
        let stars = []
        for(let i = 0; i < rating; i++) {
            stars.push(<FontAwesomeIcon icon={faStar} color="orange" key={i}/>)
        }
        return stars
    }

    useEffect(() => {
        // cycle images with fade keyframe 
        const stage = document.getElementById("stage");
        const arr = document.querySelectorAll("#stage > .fade-content");
        const fadeComplete = (e) => stage.appendChild(e.currentTarget);
        for(var i=0; i < arr.length; i++) {
            arr[i].addEventListener("animationend", fadeComplete, false);
        }
    }, []); //Runs only on the first render

    return (
        <>
            <section className="m-auto relative min-h-fit h-full overflow-hidden from-gray-700 to-gray-500 bg-gradient-to-b w-full" id="reviews">
                {/* <h1 className="text-center text-6xl text-white py-8">REVIEWS</h1> */}
                <div className="m-auto" id="review-item">
                    <a 
                        href="https://www.google.com/maps/place/Summit+Exteriors/@39.4911747,-74.9851424,17z/data=!4m7!3m6!1s0x89c7330fc80b3da1:0x8b932d512273dab6!8m2!3d39.4911747!4d-74.9829537!9m1!1b1" 
                    >  
                        <div className="w-full h-full text-2xl m-auto" id="stage">

                            { edges.map( review => (

                                <div key={review.node.id} className="fade-content flex flex-col h-full w-4/5 m-auto px-8">
                                    <i id="quote">
                                        <FontAwesomeIcon icon={faQuoteLeft} className="flex flex-col justify-content-start"/>
                                    </i> 
                                    <div className="flex text-center text-white">
                                        {
                                            review.node.text.length > 450 ? 
                                                (<p className=" text-xl font-serif">{`${review.node.text.substring(0, 450)} ...`}</p>) :
                                                (<p className=" text-xl font-serif">{review.node.text}</p>)
                                        }
                                    </div> 
                                    <div className="m-auto">
                                        { starRating(review.node.rating) }  
                                    </div>
                                    <div className="p-0 text-white text-center">
                                        <p>
                                            {review.node.author_name}
                                        </p>
                                        <p>Google Reviews</p>
                                            
                                        
                                        <p className="p-0 text-white">{review.node.relative_time_description}</p>
                                        <p className="p-0 text-white"></p>
                                    </div>
                                    <i id="quote">
                                        <FontAwesomeIcon icon={faQuoteRight} className="flex flex-col float-right" />
                                    </i>     
                                </div>
                            ))}
                        </div>   
                    </a>
                </div>
            </section>
            
        </>
    );
                
};

export default ReviewItem;