import React, { useEffect, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import useElementOnScreen from "../../utilities/useElementObserver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPeopleRoof } from "@fortawesome/free-solid-svg-icons";


const Community = () => {
    
    const targetRef = useRef(null);
    const isVisible = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0
    }, targetRef);


    useEffect(() => {
        // cycle images with fade keyframe 
        const stage = document.getElementById("stage1");
        const arr = document.querySelectorAll("#stage1 > .fade-content");
        const fadeComplete = (e) => stage.appendChild(e.currentTarget);
        for(var i=0; i < arr.length; i++) {
            arr[i].addEventListener("animationend", fadeComplete, false);
        }
    }, []); //Runs only on the first render
    

  return (
      <>
          <section className="" >
            <div className="m-auto flex flex-col place-items-center w-full h-full">
                <div className="bg-blue-900 sm:grid sm:grid-rows-1 py-12 w-full h-fit m-auto" >
                    <div ref={targetRef} className={isVisible? "slide-rt show" : "slide-rt "}>
                        <p className="container pb-4 row-start-1 row-span-1 md:text-2xl text-lg w-full text-gray-200 text-center m-auto "> 
                            Here at Summit Ext, we are committed to <strong className="text-white">quality, excellence, </strong>and <strong className="text-white">affordability</strong>. 
                        </p>
                        <p className="container pt-4 row-start-1 row-span-1 md:text-2xl text-lg w-full text-white text-center m-auto">
                            We treat our customers like family and are glad to be your friendly neighborhood roofer!
                        </p>
                    </div>
                </div>
                  <div className='h-[75vh] sm:grid sm:grid-cols-2 gap-12 m-auto flex flex-col place-items-center p-4'> 
                    <div className="sm:col-start-1 sm:col-span-1 flex h-full w-full m-auto p-8">
                        <StaticImage 
                            src="../images/bestOfTheBest.png" 
                            alt="Daily Journal Best of the Best 2021"
                            formats={["auto", "webp", "avif"]} 
                            layout="constrained"
                            objectFit="center"
                            className="m-auto"
                        />
                    </div>   
                      <div className="left-1/2 -translate-x-1/2 sm:col-start-2 flex place-items-center h-full w-full" id="stage1">
                        <div className="fade-content place-content-center" id="fade-content1">
                            <StaticImage
                                src="../images/roofFamily.jpeg"
                                alt="family with a roof"
                                formats={["auto", "webp", "avif"]} 
                                // width={550} 
                                // height={385}
                                className="m-auto"
                                
                            />
                        </div>
                        <div className="fade-content place-content-center" id="fade-content2">
                            <StaticImage
                                src="../images/roofFamily2.jpeg"
                                alt="family with a roof"  
                                formats={["auto", "webp", "avif"]} 
                                // width={550} 
                                // height={385}
                                className="m-auto"
                                
                                />
                        </div>
                        <div className="fade-content place-content-center" id="fade-content3">
                            <StaticImage
                                src="../images/roofFamily3.jpeg" 
                                alt="family with a roof" 
                                formats={["auto", "webp", "avif"]} 
                                // width={550} 
                                // height={385}
                                className="m-auto"
                                
                                />
                        </div>
                            
                    </div>
                    
                </div>        
            </div>
            <div className="p-4 sm:grid sm:grid-rows-1 w-full m-auto bg-blue-900 text-gray-200 " >
                <p className="container md:text-2xl text-lg row-start-1 row-span-1 w-full text-center m-auto "> 
                    We know you're trusting us with more than a roof, it's a part of your family's home, and
                    we take pride in our work.
                </p>
                <FontAwesomeIcon icon={faPeopleRoof} className="px-4 md:text-6xl text-4xl text-white m-auto w-full" />
            </div>
            

        </section>
    </>
  )
};

export default Community;