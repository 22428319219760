import React, { useEffect, useRef } from 'react';
import { StaticImage } from "gatsby-plugin-image";
import Button from "./Button";
import { gsap } from "gsap";
import {useStaticQuery, graphql} from 'gatsby'

const Splash = () => {

    // cloudinary video query //
    const data = useStaticQuery(graphql`
        query {
            cloudinaryMedia(public_id: {eq: "summit/summit-splash-vid_bqwr0n"}) {
            secure_url
            }
        }
        `
    );
    const { secure_url } = data.cloudinaryMedia;

    
    // GSAP animation stuff // 
    const btnRef = useRef();
    // const btn1 = gsap.utils.selector(btnRef);

    // wait until DOM has been rendered
    useEffect(() => {
        gsap.from(btnRef.current, { duration: 2.5, delay: 2, y: "-100%", opacity: 0, ease: "elastic"})
        // .from(btn1(".btn1"), { duration: 2.5, delay: 2, background: "white"})
    }, []);

    return (
        <section className="bg-black w-full relative h-screen" id="splash"  >
            <div className=" h-full w-full">
                
                <video playsInline autoPlay muted loop 
                    className="w-full">
                    <source src={secure_url} type="video/mp4" loading="eager">
                    </source>
                    Your browser does not support the video tag.
                </video>
                <div className="absolute flex flex-col md:grid md:grid-col bottom-0 left-1/2 -translate-x-1/2 m-auto w-full z-10">
                    <div className="relative w-full m-auto md:grid md:grid-cols-2 p-8 flex flex-col">

                        <div ref={btnRef} className="w-full flex place-items-center m-auto p-4 rounded-lg">
                            <a href="https://calendly.com/summitextnj" className="m-auto">
                                <Button 
                                    value={"Book a Free Estimate!"}
                                    className="m-auto"
                                />
                            </a>
                        </div>
                        <div className="pb-4 flex place-items-center w-full m-auto">   
                            <StaticImage
                                src="../images/summit_logo_transparent.png"
                                formats={["auto", "webp", "avif"]}
                                placeholder="none"
                                alt=""
                                className="m-auto object-center"
                                layout="constrained"
                                objectFit='center'
                                height={300}
                                width={450}
                            />   
                        </div>
                    </div>
                </div>    
            </div>
            {/* <div ref={svgRef}>
                <TriangleNegative  className="absolute bottom-0"/>
            </div> */}
            
        
        </section>
    )
}

export default Splash;