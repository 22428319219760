import React, { useRef} from "react"
import useElementOnScreen from "../../utilities/useElementObserver"
import { useStaticQuery, graphql } from "gatsby"

export default function Videos() {
  // cloudinary video query //
  const data = useStaticQuery(graphql`
      query {
        cloudinaryMedia(public_id: {eq: "summit/summit_video_new_g00ed2"}) {
          public_id
          secure_url
        }
      }
    `
  );
  const {secure_url} = data.cloudinaryMedia;

  // element observer stuff //
  const targetRef = useRef(null)
  const isVisible = useElementOnScreen(
    {
      root: null,
      rootMargin: "0px",
      threshold: 0.2,
    },
    targetRef
  )

  const playVideo = e => {
    e.stopPropagation()
    e.target.play()
  }
  const stopVideo = e => {
    e.target.pause()
  }


  return (
    <section className="">
      <div className="w-full h-full flex flex-col">
        <div className="sm:text-4xl text-2xl p-8 text-center bg-gray-300 text-blue-900 w-full">
          <h2 className="">Projects we are proud of</h2>
        </div>
        <div className="videos grid grid-cols-2 w-full h-full p-4 px-8 gap-4 md:gap-12 relative">
          {/* <div className="vidContainer"> */}
          <div className="vidBox sm:col-start-1 sm:col-span-1 shadow-lg rounded-lg overflow-hidden">
            <video
              onMouseEnter={playVideo}
              onMouseLeave={stopVideo}
              autoplay
              muted
            >
              <source
                src={"https://res.cloudinary.com/amesdean/video/upload/v1658966561/summit/summit_vid2_htgky0.mp4"}
                type="video/mp4"
                loading="lazy"
              ></source>
              Your browser does not support the video tag.
            </video>
          </div>
          {/* </div>
            <div className="vidContainer"> */}
          <div className="vidBox sm:col-start-2 sm:col-span-1 shadow-lg rounded-lg overflow-hidden bg-no-repeat bg-cover">
            <video
              onMouseEnter={playVideo}
              onMouseLeave={stopVideo}
              autoplay
              muted
            >
              <source
                src={"https://res.cloudinary.com/amesdean/video/upload/v1658966609/summit/summit_vid1_p1qx5o.mp4"}
                type="video/mp4"
                loading="lazy"
              ></source>
              Your browser does not support the video tag.
            </video>
          </div>
          {/* </div>
            <div className="vidContainer"> */}
          <div className="vidBox sm:col-start-1 sm:col-span-1 shadow-lg rounded-lg overflow-hidden bg-no-repeat bg-cover">
            <video
              onMouseEnter={playVideo}
              onMouseLeave={stopVideo}
              autoplay
              muted
            >
              <source
                src={"https://res.cloudinary.com/amesdean/video/upload/v1658966518/summit/summit_vid3_xrzopx.mp4"}
                type="video/mp4"
                loading="lazy"
              ></source>
              Your browser does not support the video tag.
            </video>
          </div>
          {/* </div>
            <div className="vidContainer"> */}
          <div className="vidBox sm:col-start-2 sm:col-span-1 shadow-lg rounded-lg overflow-hidden bg-no-repeat bg-cover">
            <video
              onMouseEnter={playVideo}
              onMouseLeave={stopVideo}
              autoplay
              muted
            >
              <source
                src="https://res.cloudinary.com/amesdean/video/upload/v1653849760/summit/summit_video_new_g00ed2.mp4"
                type="video/mp4"
                loading="lazy"
              ></source>
              Your browser does not support the video tag.
            </video>
          </div>
          {/* </div> */}
        </div>
      </div>
    </section>
  )
}
