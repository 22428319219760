import * as React from "react";
import { Helmet } from "react-helmet";
import About from "../components/About";
import Community from "../components/Community";
import Contact from "../components/Contact";
import Financing from "../components/Financing";
import Layout from "../components/Layout.js";
import ReviewItem from '../components/ReviewItem';
import Services from "../components/Services";
import Splash from "../components/Splash";
import Videos from "../components/Videos";
import Seo from "../components/seo";


const IndexPage = ({ children }) => {

    return ( 
        <Layout >
            <div className="">
                <Splash />
                <About /> 
                <Services />
                <ReviewItem />
                <Community/>
                <Videos/>
                <Financing />
                <Contact/>
            </div> 
        </Layout>
    )
}

export default IndexPage


export const Head = ({ location, data = {}, pageContext = {} }) => (
    <>
        <Helmet htmlAttributes={{ lang: "en", amp: "true" }}>
            <Seo />
        </Helmet>
    </>
)