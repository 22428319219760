import React from 'react'

function Button({value, onclick}) {
  return (
    <button 
      type="button" 
      className="px-8 py-4 font-semibold border rounded m-auto text-white hover:bg-white hover:border-blue-900 hover:text-blue-900 transition-all "
      onClick={onclick}
      > {value}
    </button>
  )
}

export default Button;